<template>
  <div class="main">
    <NavComponent />
    <main class="content">
      <RouterView />
    </main>
    <!-- Popup Notification -->
    <div :class="['popup-container', { 'popup-collapsed': isCollapsed }]">
      <div class="popup-content">
        <span class="popup-icon" @click="togglePopup">⚠️</span>
        <p class="notification-text" v-if="!isCollapsed">
          Onze website is nog in ontwikkeling, waardoor niet alle producten
          zichtbaar zijn. Bekijk daarom de website van onze leverancier en vraag
          een offerte aan op basis van de productnaam!
        </p>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script setup>
import NavComponent from "./components/NavComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";

import { ref } from "vue";

// State for collapsing/expanding the popup
const isCollapsed = ref(true);

// Function to toggle popup size
const togglePopup = () => {
  isCollapsed.value = !isCollapsed.value;
};

// import { onMounted } from "vue";
// import axios from "axios";

// // Fetching products from the API
// function fetchProducts() {
//   const apiUrl = process.env.VUE_APP_API_URL; // Base URL from environment variables

//   axios
//     .get(apiUrl + "/api/products")
//     .then((response) => {
//       console.log("is this working?", response.data);
//     })
//     .catch((errorResponse) => {
//       // Handle error scenario
//       console.error("Error fetching products:", errorResponse);
//     });
// }

// // Fetch products when the component is mounted
// onMounted(fetchProducts);
</script>

<style>
/* Reset any default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Styles for the app container */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-image: url("./assets/Color logo - no background.png");
  background-size: 200px 60px; /* Add spacing between the logos */
  background-repeat: repeat;
  background-position: center; /* Align pattern */
  background-attachment: fixed; /* Keep background fixed */
}

/* The main container should take up all available vertical space */
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes full height of the viewport */
  background-color: rgba(
    255,
    255,
    255,
    0.95
  ); /* Add background to content for readability */
}

/* The content should expand to fill the remaining space */
.content {
  flex: 1; /* This allows the content to grow and fill the space */
  padding: 0rem; /* Optional: Add some padding around your content */
}

/* Footer styling */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem;
  flex-shrink: 0; /* Prevent the footer from shrinking */
}

/* Navigation link margin for spacing */
.nav-link {
  margin-left: 10px;
  margin-right: 10px;
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px; /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Darker thumb for better contrast */
  border-radius: 0px; /* Rounded scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Slightly darker on hover */
}

::-webkit-scrollbar-track {
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Light background for the scrollbar track */
  border-radius: 0px;
}

/* Apply to the body or specific scrollable elements */
body {
  overflow-y: auto; /* Ensure scrolling is enabled */
  scroll-behavior: smooth; /* Smooth scrolling */
}

.scrollable {
  overflow-y: auto; /* Enable scrolling for specific elements */
  max-height: 100vh; /* Set max height for the scrollable content */
}

/* Main popup container */
/* Main popup container */
.popup-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; /* Full width */
  max-width: 100%;
  background: #f8d7da;
  color: #721c24;
  padding: 15px 20px;
  border: 2px solid #dc3545;
  border-radius: 5px;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center; /* Centers vertically */
  justify-content: center; /* Centers horizontally */
  font-size: 16px;
  text-align: center; /* Ensures text stays centered */
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out,
    left 0.3s ease-in-out, transform 0.3s ease-in-out;
  margin-bottom: 15px;
}

/* When collapsed, move to the right */
.popup-collapsed {
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: flex-end; /* Align to the right */
  align-items: center;
  width: 60px; /* Set a fixed width for the collapsed state */
  left: auto;
  right: 20px; /* Move to the right */
  transform: none;
  border-radius: 10px;
}

/* Hide text when collapsed */
.popup-collapsed p {
  display: none;
  margin-bottom: 0px !important;
}

/* Warning icon - blinking effect */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.popup-icon {
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  animation: blink 1.2s infinite;
}

/* Hide text when collapsed */
.popup-collapsed p {
  display: none;
  margin-bottom: 0px !important;
}

.notification-text {
  /* display: none; */
  margin-bottom: 0px !important;
}
</style>
