<template>
  <div class="container">
    <h1>Privacy Policy Panjsher B.V.</h1>

    <section>
      <h2>1. Inleiding</h2>
      <p>
        Welkom bij Panjsher B.V. Wij hechten veel waarde aan de bescherming van
        uw persoonsgegevens. In deze privacy policy leggen wij uit welke
        gegevens wij verzamelen, waarom wij deze verzamelen, hoe wij deze
        beschermen en welke rechten u heeft. Deze policy is van toepassing op
        alle gegevens die worden verzameld via onze website en diensten.
      </p>
    </section>

    <section>
      <h2>2. Welke gegevens verzamelen wij?</h2>
      <p>Wij kunnen de volgende persoonsgegevens verzamelen:</p>
      <ul>
        <li>Naam</li>
        <li>E-mailadres</li>
        <li>Telefoonnummer</li>
        <li>Adres (indien relevant voor levering)</li>
        <li>IP-adres</li>
        <li>
          Browsinggegevens via cookies (bijv. bezochte pagina’s, tijd op de
          website)
        </li>
      </ul>
    </section>

    <section>
      <h2>3. Hoe gebruiken wij uw gegevens?</h2>
      <p>De door u verstrekte gegevens kunnen wij gebruiken voor:</p>
      <ul>
        <li>
          Het verwerken van bestellingen en het leveren van producten of
          diensten.
        </li>
        <li>
          Het verstrekken van informatie of beantwoorden van vragen via ons
          contactformulier.
        </li>
        <li>
          Het verbeteren van onze website en diensten via statistische analyses.
        </li>
        <li>
          Het verzenden van marketingcommunicatie (indien u hiervoor toestemming
          hebt gegeven).
        </li>
      </ul>
    </section>

    <section>
      <h2>4. Met wie delen wij uw gegevens?</h2>
      <p>
        Uw gegevens worden niet verkocht aan derden. Wij kunnen uw gegevens
        delen met:
      </p>
      <ul>
        <li>
          Leveranciers en partners die betrokken zijn bij het leveren van onze
          diensten (bijv. bezorgdiensten).
        </li>
        <li>
          Analysediensten zoals Google Analytics voor het verbeteren van onze
          website.
        </li>
      </ul>
    </section>

    <section>
      <h2>5. Hoe beschermen wij uw gegevens?</h2>
      <p>
        Wij nemen de volgende maatregelen om uw persoonsgegevens te beveiligen:
      </p>
      <ul>
        <li>Gebruik van SSL-certificaten voor veilige gegevensoverdracht.</li>
        <li>
          Beperkte toegang tot gegevens voor alleen geautoriseerd personeel.
        </li>
        <li>Regelmatige updates en beveiligingscontroles van onze systemen.</li>
      </ul>
    </section>

    <section>
      <h2>6. Cookies en trackingtechnologieën</h2>
      <p>
        Wij maken gebruik van cookies om uw ervaring op onze website te
        verbeteren. Dit omvat:
      </p>
      <ul>
        <li>
          Functionele cookies: noodzakelijk voor de werking van de website.
        </li>
        <li>Analytische cookies: om websitegebruik te analyseren.</li>
        <li>
          Marketingcookies: voor gepersonaliseerde advertenties (indien van
          toepassing).
        </li>
      </ul>
      <p>U kunt cookies beheren of uitschakelen via uw browserinstellingen.</p>
    </section>

    <section>
      <h2>7. Uw rechten</h2>
      <p>U heeft het recht om:</p>
      <ul>
        <li>
          Uw persoonsgegevens in te zien, te corrigeren of te laten verwijderen.
        </li>
        <li>
          Bezwaar te maken tegen het gebruik van uw gegevens voor specifieke
          doeleinden.
        </li>
        <li>
          Een klacht in te dienen bij de Autoriteit Persoonsgegevens als u van
          mening bent dat wij niet voldoen aan de privacywetgeving.
        </li>
      </ul>
      <p>
        Voor het uitoefenen van uw rechten kunt u contact met ons opnemen via de
        contactgegevens onderaan deze policy.
      </p>
    </section>

    <section>
      <h2>8. Bewaartermijn</h2>
      <p>
        Wij bewaren uw persoonsgegevens niet langer dan nodig is voor de
        doeleinden waarvoor ze zijn verzameld, tenzij wettelijk anders vereist.
      </p>
    </section>

    <section>
      <h2>9. Contactinformatie</h2>
      <p>
        Voor vragen of verzoeken met betrekking tot deze privacy policy kunt u
        contact met ons opnemen:
      </p>
      <ul>
        <li><strong>Bedrijfsnaam:</strong> Panjsher B.V.</li>
        <li><strong>E-mailadres:</strong> info@panjsher.be</li>
        <li><strong>Telefoonnummer:</strong> +</li>
        <li>
          <strong>Adres:</strong> Steenweg op Mol 187, 2360 te Oud-Turnhout
        </li>
      </ul>
    </section>

    <section>
      <h2>10. Wijzigingen in deze privacy policy</h2>
      <p>
        Wij behouden ons het recht voor om deze privacy policy te wijzigen.
        Eventuele wijzigingen zullen worden gepubliceerd op deze pagina. Wij
        raden aan deze policy regelmatig te controleren.
      </p>
      <p><strong>Laatst bijgewerkt:</strong> 15-01-2025</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicyPage",
};
</script>

<style scoped>
.privacy-policy {
  background-color: #f9f9f9;
  padding: 40px 20px;
  font-family: "Arial", sans-serif;
  line-height: 1.7;
  color: #333;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: left;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.8rem;
  color: #34495e;
  margin-top: 30px;
  margin-bottom: 15px;
  border-left: 4px solid #3498db;
  padding-left: 10px;
}

p {
  font-size: 1.1rem;
  text-align: left;
  margin-bottom: 10px;
}

ul {
  text-align: left;
}

section {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }
}
</style>
