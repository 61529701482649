<template>
  <div class="location-page">
    <h1 style="font-weight: bold">
      Bezoek Panjsher Tegels - Route en Openingstijden
    </h1>
    <div class="map-container">
      <iframe
        :src="mapSrc"
        width="600"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
const mapSrc =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2494.291744852402!2d5.003796776114648!3d51.305756625414645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6b412875ba2b3%3A0xd1900719dd4c4989!2sSteenweg%20op%20Mol%20187%2C%202360%20Oud-Turnhout%2C%20Belgi%C3%AB!5e0!3m2!1snl!2snl!4v1723308983042!5m2!1snl!2snl";

// Vervang de bovenstaande mapSrc waarde door de Google Maps embed link van jouw specifieke locatie.
</script>

<style scoped>
.location-page {
  padding: 20px;
  text-align: center;
}

.map-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

iframe {
  width: 100%;
  max-width: 1000px;
  height: 450px;
  border: none;
  border-radius: 15px;
}
</style>
