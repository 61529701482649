<template>
  <div class="collaborations-page">
    <!-- Header Section -->
    <section class="header-section">
      <div class="header-content">
        <h1>Panjsher Tegels - Officiële Dealer van Topmerken</h1>
        <p>Wij werken samen met toonaangevende merken in de tegelindustrie.</p>
      </div>
    </section>

    <!-- Main Collaborations -->
    <div class="container">
      <RouterLink
        class="collaboration-link"
        v-for="collaboration in collaborations"
        :key="collaboration.id"
        :to="collaboration.href"
      >
        <div class="collaboration-item">
          <img
            :src="collaboration.image"
            :alt="collaboration.name"
            class="brand-logo"
          />
          <div class="collaboration-text">
            <h2>{{ collaboration.name }}</h2>
            <p v-html="collaboration.description"></p>
          </div>
        </div>
      </RouterLink>
    </div>

    <!-- Other Collaborations Section -->
    <section class="other-collaborations-section">
      <div class="container">
        <h2>Andere Samenwerkingen</h2>
        <div class="other-collaborations-grid">
          <div
            v-for="brand in otherCollaborations"
            :key="brand.id"
            class="brand-item"
          >
            <p>{{ brand.name }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { RouterLink } from "vue-router";

// Main collaborations with detailed pages
const collaborations = ref([
  {
    id: 1,
    name: "Excluton",
    image: new URL(
      "../assets/brand-logos/Excluton-removebg-preview.png",
      import.meta.url
    ).href,
    href: "/samenwerkingen/excluton",
    description:
      "<strong>Officiële Excluton Dealer</strong> – Dé specialist in hoogwaardige bestratingsmaterialen. Met de innovatieve Kera Twice-tegels en sierbestrating van topkwaliteit breng je stijl en duurzaamheid naar jouw buitenruimte.",
  },
  {
    id: 2,
    name: "MBI",
    image: new URL(
      "../assets/brand-logos/LOGO_MBI_2015_EN_v1.png",
      import.meta.url
    ).href,
    href: "/samenwerkingen/mbi",
    description:
      "<strong>Officiële MBI Dealer</strong> – Al meer dan 75 jaar staat MBI voor innovatie en duurzaamheid in bestratingsmaterialen. Met GeoCeramica® combineer je betonsterkte met een luxe keramische uitstraling.",
  },
  {
    id: 3,
    name: "Mosa",
    image: new URL("../assets/brand-logos/mosa-logo.png", import.meta.url).href,
    href: "/samenwerkingen/mosa",
    description:
      "<strong>Officiële Mosa Dealer</strong> – Tijdloze tegelontwerpen van topkwaliteit met een focus op duurzaamheid. Mosa is pionier in Cradle to Cradle®-gecertificeerde tegels voor interieur en exterieur.",
  },
  {
    id: 4,
    name: "Marazzi",
    image: new URL("../assets/brand-logos/MARAZZI-LOGO.png", import.meta.url)
      .href,
    href: "/samenwerkingen/marazzi",
    description:
      "<strong>Officiële Marazzi Dealer</strong> – Italiaanse elegantie in keramische en porseleinen tegels. Marazzi biedt stijlvolle collecties zoals Treverk, Grande en Stone Look voor een tijdloze uitstraling.",
  },
]);

// Other collaborations (text only)
const otherCollaborations = ref([
  { id: 5, name: "Emil Ceramica Forme" },
  { id: 6, name: "Italgraniti" },
  { id: 7, name: "La Faenza Gea" },
  { id: 8, name: "Margres" },
  { id: 9, name: "Fioranese" },
  { id: 10, name: "Kronos Le Reverse" },
  { id: 11, name: "Impronta" },
  { id: 12, name: "Cercom" },
  { id: 13, name: "Living Ceramics" },
  { id: 14, name: "Lea tegels" },
  { id: 15, name: "Keope" },
  { id: 16, name: "Florim" },
  { id: 17, name: "Keope District" },
  { id: 18, name: "Ascot St. Remy" },
  { id: 19, name: "Blustyle" },
  { id: 20, name: "Navarti" },
]);
</script>

<style scoped>
.collaborations-page {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Header Section */
.header-section {
  background: url("../assets/tile_shop_panjsheer.jpg") no-repeat center center;
  background-size: cover;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.header-content {
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
}

.header-content h1 {
  font-size: 2.5rem;
}

/* Collaboration Items */
.container {
  padding: 50px 20px;
}

.collaboration-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.collaboration-item {
  display: flex;
  align-items: center;
  gap: 20px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.collaboration-item:hover {
  transform: scale(1.02);
  background-color: rgba(128, 128, 128, 0.448);
  color: black;
}

.brand-logo {
  width: 150px;
  height: auto;
  object-fit: contain;
}

.collaboration-text h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.collaboration-text p {
  font-size: 1.1rem;
  color: #555;
}

/* Other Collaborations Section */
.other-collaborations-section {
  padding: 50px 20px;
  background: #f8f8f8;
  text-align: center;
}

.other-collaborations-section h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
}

/* Other Collaborations Grid */
.other-collaborations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  justify-content: center;
}

/* Equal Card Size */
.brand-item {
  width: 200px;
  height: 100px;
  background: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.brand-item:hover {
  transform: scale(1.05);
  background-color: #1b3976;
  color: white;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .other-collaborations-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  .brand-item {
    width: 150px;
    height: 90px;
  }
}
</style>
